import $ from "jquery";
(() => {

})();

$('document').ready(function() {
    // JS per blocco
    var limit = $("#block-block-4").width();
    var x1 = $("#bici1").width();
    var x2 = $("#bici2").width();
    var x3 = $("#bici3").width();
    var x4 = $("#bici4").width();

    function loop1() {
        $('#bici1').css({left: -x1});

        $('#bici1').animate({
            left: limit + x1
        }, 8000, 'linear', function() {
            loop1();
        });
    }

    function loop2() {
        $('#bici2').css({left: limit + x2});

        $('#bici2').animate({
            left: -400
        }, 9000, 'linear', function() {
            loop2();
        });
    }

    function loop3() {
        $('#bici3').css({left: -x3});

        $('#bici3').animate({
            left: limit + x3
        }, 10000, 'linear', function() {
            loop3();
        });
    }

    function loop4() {
        $('#bici4').css({left: limit + x4});

        $('#bici4').animate({
            left: -300
        }, 11000, 'linear', function() {
            loop4();
        });
    }

    loop1();
    loop2();
    loop3();
    loop4();
});